enum AriaRole {
    ALERT = 'alert',
    ALERTDIALOG = 'alertdialog',
    APPLICATION = 'application',
    ARTICLE = 'article',
    BANNER = 'banner',
    BUTTON = 'button',
    CHECKBOX = 'checkbox',
    COLUMNHEADER = 'columnheader',
    COMBOBOX = 'combobox',
    DIALOG = 'dialog',
    DOCUMENT = 'document',
    FIGURE = 'figure',
    GRID = 'grid',
    GRIDCELL = 'gridcell',
    GROUP = 'group',
    HEADING = 'heading',
    IMG = 'img',
    LINK = 'link',
    LISTBOX = 'listbox',
    MENU = 'menu',
    MENUITEM = 'menuitem',
    MENUITEMCHECKBOX = 'menuitemcheckbox',
    MENUITEMRADIO = 'menuitemradio',
    NAVIGATION = 'navigation',
    OPTION = 'option',
    PRESENTATION = 'presentation',
    RADIO = 'radio',
    RADIOGROUP = 'radiogroup',
    REGION = 'region',
    ROW = 'row',
    ROWGROUP = 'rowgroup',
    SEARCH = 'search',
    SLIDER = 'slider',
    TAB = 'tab',
    TABLIST = 'tablist',
    TABPANEL = 'tabpanel',
    TEXTBOX = 'textbox',
    TOOLTIP = 'tooltip',
    TOOLBAR = 'toolbar',
    TREE = 'tree',
    TREEITEM = 'treeitem',
}

export default AriaRole;
